import React from 'react';

import '../Home.css';

import Supyacht from "../video/syacht_smaller.mp4";
import jester from "../images/yachts/Jester.jpeg";
import luminance from "../images/yachts/Luminance.jpeg";
import obsidian from "../images/yachts/Obsidian.jpeg";
import samadhi from "../images/yachts/Samadhi.jpg";
import sarissa from "../images/yachts/Sarissa.jpeg";
import seapearl from "../images/yachts/SeaPearl.jpeg";
import virtuosity from "../images/yachts/Virtuosity.jpg";
import kahala from "../images/yachts/Kahala.jpeg";
import ultrag from "../images/yachts/UltraG.jpeg";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';



const Superyacht3D = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <div className="infoframe">  
                        <p className="title">Superyacht 3D visualizations</p>  
                    </div>
                </div>

                <div>
                    <video width="100%" controls autoPlay loop muted>
                        <source src={Supyacht}></source>
                    </video> 
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        In the last few years, we have been developing superyacht interactive 3D models, 
                        based on detailed technical sketches, GA plans, and project documentations. 
                        Models were implemented as interactive presentation in the clients proprietary monitoring systems, 
                        as well as advertising presentations on the international events.
                        </p>
                        <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                        Among others we created models for: 
                        </p>
                     </div>
                     </div>   


                    <div className="row-wrapper25home">
                
                        <div className="kafelekhome">
                            <img src={jester} alt="jester superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.superyachttimes.com/yachts/jester-69m/overview" target='blank'>Jester</a>
                                    </h4>

                                </div>
                        </div> 
                        <div className="kafelekhome">
                            <img src={luminance} alt="luminance superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.superyachttimes.com/yachts/luminance/overview" target='blank'>Luminance</a>
                                    </h4>

                                </div>
                        </div> 
                        <div className="kafelekhome">
                            <img src={obsidian} alt="obsidian superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.superyachttimes.com/yachts/obsidian-84m/overview" target='blank'>Obsidian</a>
                                    </h4>

                                </div>
                        </div> 
                        <div className="kafelekhome">
                            <img src={samadhi} alt="samadhi superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.yachtcharterfleet.com/luxury-charter-yacht-22997/samadhi.htm" target='blank'>Samadhi</a>
                                    </h4>

                                </div>
                        </div>
                        <div className="kafelekhome">
                            <img src={sarissa} alt="sarissa superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.superyachttimes.com/yachts/sarissa-60m/overview" target='blank'>Sarissa</a>
                                    </h4>

                                </div>
                        </div>
                        <div className="kafelekhome">
                            <img src={seapearl} alt="seapearl superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.superyachttimes.com/yachts/sea-pearl-82m/overview" target='blank'>SeaPearl</a>
                                    </h4>

                                </div>
                        </div>
                        <div className="kafelekhome">
                            <img src={virtuosity} alt="virtuosity superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.yachtcharterfleet.com/luxury-charter-yacht-55262/virtuosity.htm" target='blank'>Virtuosity</a>
                                    </h4>

                                </div>
                        </div>
                        <div className="kafelekhome">
                            <img src={kahala} alt="kahala superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.superyachttimes.com/yachts/kahala/overview" target='blank'>Kahala</a>
                                    </h4>

                                </div>
                        </div>
                        <div className="kafelekhome">
                            <img src={ultrag} alt="ultrag superyacht"></img>
                                <div className='box2home'> 
                        
                                    <h4 style={{color: 'white'}}>
                                    <a href="https://www.superyachttimes.com/yachts/ultra-g" target='blank'>UltraG</a>
                                    </h4>

                                </div>
                        </div>
                        
                    </div>
                    <div className="infoframe">
                        <div className="infotext">
                            <p>
                                Client: <a href="https://fss.cc" target="_blanc">FSS</a>
                            </p>
                        </div>
                    </div> 

                <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/Work")}> 
                        BACK
                    </button> 
                </div> 

                <Footer />
            </div>
        )
}

export default Superyacht3D;