import React, {useState} from "react";
import { Link, useNavigate } from 'react-router-dom';

import Portfolio from "./portfolio";

import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import ScrollToTop from "react-scroll-to-top";

//images

import Footer from './components/Footer/Footer';


const PortoflioDS = () => {

        const navigate = useNavigate();
  
        const [items, setItems] = useState(Portfolio);
        const [active, setActive] = useState(false);
        const filterItem = (categItem) => {
        const updateItems = Portfolio.filter((curElem) => {            
        
         if (categItem === "personal") {
            return (curElem.category === categItem && curElem.who === "ds");
          }
          else{
            return ((curElem.category === categItem || curElem.category === "designtech") && curElem.who === "ds")
          }
         

          //console.log()
             
          });

          setItems(updateItems);
          setActive(true);
        };

        return (
         
         <div> 

            <ScrollToTopOnMount />
            <ScrollToTop smooth />

            <div className="row">
{/*             <div className="infoframe">
              <div className="infotext">  */}
                    {/* <p className="beginning" style={{fontSize: '3vw', textAlign: 'left'}}> */}
                    <p className="heading">
          
                    </p>

                  <div className="beginning">
                  <div className="infoframe">        
                      <p className='title'>Dorota's works</p>    
                  </div>
                  </div>
                  
                  <div className="rowbtn">
                    <div className="column4">
                      <p className="pbuttonsel">
                        <a href="javascrip:0;" onClick={() => (filterItem("design"))}>
                          <span style={{color: "white"}}> 
                            COMMERCIAL
                          </span>
                        </a>
                      </p>
                    </div>
                    <div className="column4">
                      <p className="pbuttonsel">
                        <a href="javascrip:0;" onClick={() => (filterItem("personal"))}>
                          <span style={{color: "white"}}> 
                            PERSONAL
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                  </div>
{/*                 </div>
              </div>  */}


            <div className="row">
              {items.map((elem) => {
              const { id, path, image, www, name, description} = elem;
              if (elem.who === "ds")
              {
                return (
                  <div className="column" id={id}>
                    <div className="content">
                      <Link to={path} onClick={()=>navigate({path})}>
                          <img src={image} alt ={name} style={{width: '100%'}}></img>    
                      </Link> 
                      <p style={{color: 'white'}}>{description} <a href={www} target='_blank'> {name}</a></p>
                    </div>
                  </div>
                );
              }
              })}

            </div>

          <Footer />
           
         </div>
        );
  }


export default PortoflioDS;