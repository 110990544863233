import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import okladka from '../images/17sekund/2.jpg';
import ptaki from '../images/17sekund/6.jpg';
import susza from '../images/17sekund/4.jpg';
import pszczola from '../images/17sekund/5.jpg';
import ryby from '../images/17sekund/7.jpg';
import reklamowka from '../images/17sekund/3.jpg';
import tyl from '../images/17sekund/1.jpg';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';

const Seconds = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <div className="infoframe">  
                        <p className="title">17 sekund</p>  
                    </div>
                </div>


                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            <img src={okladka} alt="zdjęcie mini książeczki 17 sekund"></img>
                        </p>
                        <p>
                            <img src={ptaki} alt="ilustracja ptaków w płonącym lesie"></img>
                        </p>
                        <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                            The artistic book was created in a unique collaboration between the illustrator Dorota Stefan, and the poet Magdalena Wrona.
                            Sketches of illustrations were the inspiration for the poetry, which then became the final image.
                            Each of the authors refers to the same dramatic events using the tool of artistic expression she knows the best.
                            Although the authors worked almost independently, the story is coherent. 
                            Its subject is ourselves and our inability to adapt to nature, which leads to catastrophic consequences.
                        </p>
                        <p>
                            Each illustration in this book is one second in a rythmic countdown to the apocalyptic end of the human
                            world. The illustrations with the accompanying poems create a homogenous story about us as a species seen 
                            through the eyes of other inhabitants of our planet and by the Earth itself. The story prompts reflection
                            on the awareness of man's role in the ecosystem.    
                        </p>
                        <p>
                            <img src={susza} alt="zdjęcie fragmentu rozłożonej książeczki"></img>
                        </p>
                        <p>
                            <img src={pszczola} alt="zdjęcie fragmentu wiersza"></img>
                        </p>
                        <p>
                            The book consists of 36 + 4 pages. < br/>
                            Printed on the Pergraphica Classic Smooth paper. < br/>
                            Printed and bound by Kolorowy Zawrót Głowy in Bielsku-Białej. < br/>
                            Stitching with black thread. <br />
                            Font: Gabriola.
                        </p>
                        <p>
                            <img src={ryby} alt="fragment ilustracji o martwych rybach z Odry"></img>
                        </p>
                        <p>
                            <img src={reklamowka} alt="ilustracja reklamowki plastikowej"></img>
                        </p>
                        <p>
                            <img src={tyl} alt="tyl okladki"></img>
                        </p>
                        <p>
                            Please contact us if you would like to have the book.
                            <br />
                            <br />
                            The total income from the sale will go to the statutory activities of the
                            <a href="https://pracownia.org.pl/" target="_blank"> Pracownia na rzecz Wszystkich Istot </a> 
                            - to activities whose aim is protecting the wild nature.
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top"> 
               
                    <button className='button' onClick={()=>navigate("/Work")}> 
                        BACK
                    </button> 
             
                </div>

                <Footer />
            </div>
        )
}

export default Seconds;